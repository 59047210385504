@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    background-image: url('../img/background.jpg');
    background-size: cover;
    /*background: linear-gradient(to right,#7528de,#18cec4);*/
    background: #6b7280 no-repeat center;
}

.vertical-text {
    writing-mode: vertical-rl; /* vertical text, right-to-left */
    text-orientation: upright; /* optional, can be 'mixed' or 'upright' depending on the effect you want */
}

@keyframes expandAnimation {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}

/* Apply the animation to the mobile aside when it's open */
.mobile-aside-expand {
    animation: expandAnimation 0.5s ease-out;
}

/* Add transition for smoother effects */
aside {
    transition: width 0.5s ease-out;
}

.tab-scroll::-webkit-scrollbar {
    height: 5px;
    width: 5px;
  }

.tab-scroll::-webkit-scrollbar-track {
    background: transparent;
  }

.tab-scroll::-webkit-scrollbar-thumb {
    background: transparent;
  }

.tab-scroll::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

  .empty-scroll::-webkit-scrollbar {
    height: 0px;
    width: 0px;
  }

.empty-scroll::-webkit-scrollbar-track {
    background: transparent;
  }

.empty-scroll::-webkit-scrollbar-thumb {
    background: transparent;
  }

.empty-scroll::-webkit-scrollbar-thumb:hover {
    background: transparent;
  }

.edit-spinner {
    animation: rotate 10s linear infinite;
}

@keyframes rotate {
    to {
        transform: rotate(359deg); /* some browsers don't display spin when it is 360 deg */
    }
}
@keyframes rotate-rev {
    to {
        transform: rotate(-359deg); /* reverse direction rotate */
    }
}